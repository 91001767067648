import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import tarifReducer from './slices/service.tariff';
import eventsReducer from './slices/service.events';
import rcalendarReducer from './slices/service.rcalendar';
import mapReducer from './slices/service.map';
import routeMapReducer from './slices/service.routeMap';
import mapLayersReducer from './slices/service.mapLayers';
import mapZonaReducer from './slices/service.mapZona';
import sigfoxTrackersReducer from './slices/service.sigfoxTrackers';
import enterpriseReducer from './slices/enterprise';
import agreementReducer from './slices/agreement';
import bugsReducer from './slices/bugs';
import vagonReducer from './slices/service.infovag';
import confirmationReducer from './slices/confirmation';
import notifyReducer from './slices/ws_reducers';
import formulyarReducer from './slices/service.formulyars';
import contractReducer from './slices/service.contracts';
import regulatorChainsReducer from './slices/service.regulatorChains';
import publicationsReducer from './slices/publications';
import cargoPartsReducer from './slices/service.cargoParts';
import cargoGroupsReducer from './slices/service.cargoGroups';
import cargoRoutesReducer from './slices/service.cargoRoutes';
import groupChainsReducer from './slices/service.groupChains';
import offersReducer from './slices/service.offers';
import exeMonitoringReducer from './slices/service.exeMonitoring';
import provisionServicesReducer from './slices/provisionServices';
import billingReducer from './slices/billing';
import widgetReducer from './slices/widget';
import resourcesReducer from './slices/service.resources';
// import orderReducer from './slices/service.order';
import charts from './slices/services.charts';

import productReducer from './slices/product';
import tolokaReducer from './slices/toloka';
import tolokaProductReducer from './slices/toloka.product';
import tolokaOrderReducer from './slices/toloka.order';
// ----------------------------------------------------------------------
import cabinetsReducer from './slices/cabinets';
import usersReducer from './slices/users';
// ----------------------------------------------------------------------
import auctionReducer from './slices/service.auction';
// -- mesplan --------------------------------------------------------------------
import reestr from './reducers/reestr';
import soglReestr from './reducers/soglReestr';
import elz from './reducers/elz';
import userMesplan from './reducers/user';
// ----------------------------------------------------------------------
import loaderReducer from './slices/loader';
import constructorReducer from './slices/constructor';
// ----------------------------
const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const tarifPersistConfig = {
  key: 'tarif',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['params', 'result'],
};

const websocketPersistConfig = {
  key: 'websocket',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['notifyLog', 'result'],
};

const mapPersistConfig = {
  key: 'map',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['mapOptions']
};

const routeMapPersistConfig = {
  key: 'routeMap',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['routeServicesPrice']
};  

const offerPersistConfig = {
  key: 'offer',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['filterModel']
};

/*
const regulatorChainsPersistConfig = {
  key: 'regulatorChain',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['activeStep']
};
*/

const rootReducer = combineReducers({
  mail: mailReducer,
  events: eventsReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  rcalendar: rcalendarReducer,
  tarif: persistReducer(tarifPersistConfig, tarifReducer),
  map: persistReducer(mapPersistConfig, mapReducer),
  routeMap: persistReducer(routeMapPersistConfig, routeMapReducer),
  mapLayers: mapLayersReducer,
  mapZona: mapZonaReducer,
  resources: resourcesReducer,
  sigfoxTrackers: sigfoxTrackersReducer,
  enterprise: enterpriseReducer,
  agreement: agreementReducer,
  vagon: vagonReducer,
  bugs: bugsReducer,
  widget: widgetReducer,
  websocket: persistReducer(websocketPersistConfig, notifyReducer),
  confirmation: confirmationReducer,
  formulyar: formulyarReducer,
  contract: contractReducer,
  cargoPart: cargoPartsReducer,
  cargoGroup: cargoGroupsReducer,
  cargoRoute: cargoRoutesReducer,
  // regulatorChain: persistReducer(regulatorChainsPersistConfig, regulatorChainsReducer),
  regulatorChain: regulatorChainsReducer,
  groupChain: groupChainsReducer,
  // offer: offersReducer,
  offer: persistReducer(offerPersistConfig, offersReducer),
  exeMonitoring: exeMonitoringReducer,
  provisionServices: provisionServicesReducer,
  cabinets: cabinetsReducer,
  users: usersReducer,
  auction: auctionReducer,
  publications: publicationsReducer,
  // mesplan
  reestr,
  soglReestr,
  elz,
  userMesplan,
  // order: orderReducer,
  product: productReducer,
  toloka: tolokaReducer,
  tolokaProduct: tolokaProductReducer,
  tolokaOrder: tolokaOrderReducer,

  charts,
  loader: loaderReducer,
  billing: billingReducer,
  constructor: constructorReducer
});

export { rootPersistConfig, rootReducer };
